import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

import { Companies } from '../types/companies';
import { Partners } from '../types/partners';
import { CompanyPartner } from '../types/companyPartner';

export type UseAuthProps = {
  loading: boolean;
  user: {
    id: string;
    license_id: string;
    is_active: boolean;
    email: string;
    need_change_password: string | null;
    created_at: string | null;
    updated_at: string | null;
    partner: Partners;
    company_partner: CompanyPartner;
  } | null;
  setUser: (_payload: UseAuthProps['user']) => void;
  getUser: () => void;
  updateUser: (_payload: UseAuthProps['user']) => void;
  signIn: (token: any, document_number: any) => void;
  sendToken: (_payload: any, admin_document_number?: any) => void;
  signOut: () => void;
  token: string | null;
  company: Companies | null;
  head: Companies | null;
  setCompany: (_company: UseAuthProps['company']) => void;
  setHead: (_head: UseAuthProps['head']) => void;
};

const useAuth = (): UseAuthProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default useAuth;
